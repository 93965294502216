import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../components/layouts/secondary-landing";
import SEO from "../../components/seo/seo";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import HeroChevron from "../../components/hero/hero-chevron";
import VimeoVideoFrame from "../../components/custom-widgets/vimeo-video-frame";
import BestBanksDefault from "../../components/best-banks/best-banks-default";
import AboutUsNavigation from "../../components/about-us/about-us-navigation";
import TwoColumnSectionWithImage from "../../components/custom-widgets/two-column-section-with-image";

import getHeroImgVariables from "../../helpers/getHeroImgVariables";

const CommunityGiving = () => {
  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(
        relativePath: { eq: "hero/about-us/community-giving/hero-community-relations-04112023-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/about-us/community-giving/hero-community-relations-04112023-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/about-us/community-giving/hero-community-relations-04112023-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/about-us/community-giving/hero-community-relations-04112023-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/about-us/community-giving/hero-community-relations-04112023-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/about-us/community-giving/hero-community-relations-04112023-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/about-us/community-giving/hero-community-relations-04112023-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "community-giving-hero",
    ...getHeroImgVariables(imgData),
    altText: "WaFd employees and other volunteers for Stuff the Bus Helping Hands in Mount Vernon, Washington",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "WaFd Bank in the Community"
          }
        }
      ]
    }
  };

  const SEOData = {
    title: "Community Relations",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: "Community Relations"
      },
      {
        name: "description",
        content:
          "WaFd Bank is committed to our communities in more than just a banking role. See how we get involved and give back."
      },
      {
        property: "og:title",
        content: "Community Relations"
      },
      {
        property: "og:description",
        content:
          "WaFd Bank is committed to our communities in more than just a banking role. See how we get involved and give back."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/about-us/community-giving"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-community-relations-04112023.jpg"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/about-us",
      title: "About Us"
    },
    {
      id: 2,
      active: true,
      title: "Community Relations"
    }
  ];

  // NOTE: update the yearly numbers here
  const numbers = {
    year: "2023",
    volunteerHours: "11,870",
    nonprofitOrganizations: "755",
    unitedWayPledges: "$376,392.91",
    unitedWayMatches: "$376,392.91",
    unitedWayTotal: "$746,585.83"
  };

  const videoData = {
    vimeoId: "841320808",
    videoTitle: "How WaFd Bank is Helping Veterans through the Wyakin Foundation",
    class: "m-auto iframe w-100 border-radius-12",
    maxWidth: "600px"
  };

  const videoWashingtonData = {
    vimeoId: "999673926",
    videoTitle: "WaFd Bank is Making a Difference at the Portland Rescue Mission - Nonprofit Organization",
    class: "m-auto iframe w-100 border-radius-12",
    maxWidth: "600px"
  };

  const CommunityDevelopmentContent = () => {
    return (
      <>
        <h2 className="font-weight-semi-bold text-success">Community Development</h2>
        <p>
          Welcome to our customer-centric world! Whether in-store, online, or on the road, our solutions offer a
          seamless brand experience. Speed up checkout with easy customer lookup, rule-based promos, and quick-sale
          buttons for popular items. Plus, our custom reporting engine provides real-time data for better business
          decisions.
        </p>
        <Link
          id="community-development-btn"
          className="btn btn-primary"
          to="/about-us/investor-relations/csr#community-development"
        >
          Community Development
        </Link>
      </>
    );
  };

  const VolunteerismContent = () => {
    return (
      <>
        <h2 className="font-weight-semi-bold text-success">Volunteerism</h2>
        <p className="mb-0">
          WaFd employees are invested in their communities and giving back. We support this by giving them time to
          volunteer with the organizations they are passionate about. In {numbers.year}, WaFd employees participated in{" "}
          {numbers.volunteerHours}
          volunteer hours to more than {numbers.nonprofitOrganizations} organizations and initiatives across our
          regional footprint.
        </p>
      </>
    );
  };

  const WashingtonFederalFoundationContent = () => {
    return (
      <>
        <h2 className="font-weight-semi-bold text-success">Washington Federal Foundation</h2>
        <p>
          As a bank dedicated to promoting home ownership, we focus on the programs that help provide affordable housing
          for low- and moderate-income individuals.
        </p>
        <Link
          id="washington-federal-foundation-btn"
          className="btn btn-primary"
          to="/about-us/washington-federal-foundation"
        >
          Washington Federal Foundation
        </Link>
      </>
    );
  };

  const UnitedWayContent = () => {
    return (
      <>
        <h2 className="font-weight-semi-bold text-success">United Way Matching Campaign</h2>
        <p className="mb-0">
          WaFd Bank matches employee contributions made to United Way agencies in all nine states. For fiscal{" "}
          {numbers.year}, total pledges from colleagues amounted to {numbers.unitedWayPledges} in charitable giving,
          WaFd matched {numbers.unitedWayMatches} in donations to United Way agencies for a total of{" "}
          {numbers.unitedWayTotal}.
        </p>
      </>
    );
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <AboutUsNavigation />
      <section className="container">
        <h1>Community Relations</h1>
        <h2 className="text-success mb-0">Love what you do. Make a difference.</h2>
      </section>
      <TwoColumnSectionWithImage isGray={true} contentOnLeft={false}>
        {{
          image: <VimeoVideoFrame {...videoData} style={{ maxWidth: "600px" }} />,
          content: <CommunityDevelopmentContent />
        }}
      </TwoColumnSectionWithImage>
      <TwoColumnSectionWithImage>
        {{
          image: (
            <>
              <StaticImage
                quality="100"
                placeholder="blurred"
                src="../../images/tx-food-bank.png"
                alt="WaFd employees presenting a check for Builders of Hope for $25,000 in Texas."
              />
              <h5 className="font-weight-semi-bold text-success mb-0 mt-3">We're In This Together</h5>
            </>
          ),
          content: <VolunteerismContent />
        }}
      </TwoColumnSectionWithImage>
      <TwoColumnSectionWithImage isGray={true} contentOnLeft={false}>
        {{
          image: <VimeoVideoFrame {...videoWashingtonData} style={{ maxWidth: "600px" }} />,
          content: <WashingtonFederalFoundationContent />
        }}
      </TwoColumnSectionWithImage>
      <TwoColumnSectionWithImage>
        {{
          image: (
            <StaticImage
              quality="100"
              placeholder="blurred"
              src="../../images/united-way-day-of-caring-600.jpg"
              alt="WaFd employees volunteering for United Way, United 4 Vegas."
            />
          ),
          content: <UnitedWayContent />
        }}
      </TwoColumnSectionWithImage>
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default CommunityGiving;
